import { useUrlBuilder } from '~/stores/url-builder'
import { storeToRefs } from 'pinia'


// const urlBuilder = useUrlBuilder()
// const { getProjectId } = storeToRefs(urlBuilder)

class Payload {
    constructor() {
        this.ProjectId = __VPAPPS__.projectId
        this.OrganizationId = __VPAPPS__.organizationId
        this.AppVersion = '0.1/alpha'
    }

    loadBanner(data) {

        this.EventTime = new Date().toISOString()

        this.OrganizationId = typeof window !== 'undefined' ? window.__VPAPPS__?.organizationId : null

        this.EntityType = 'Banner'
        this.Event = data.event //'BannerView'
        this.EventType = 'BannerEvent'

        this.MetaData_Key = ['media', 'title', 'description', 'rowIndex']
        this.MetaData_Value = [data.media, data.title, data.description, data.rowIndex]

    }

    loadShowCase(data) {

        this.EventTime = new Date().toISOString()

        this.OrganizationId = typeof window !== 'undefined' ? window.__VPAPPS__?.organizationId : null

        this.EntityType = 'ShowCase'
        this.Event = data.event //'BannerView'
        this.EventType = 'ShowCaseEvent'

        this.MetaData_Key = ['media', 'title', 'description', 'rowIndex', 'gridData']
        this.MetaData_Value = [data.media, data.title, data.description, data.rowIndex, data.gridData]

    }

    loadPage(data) {
        this.pageType = __VPAPPS__.page.type

        this.url = location.href

        this.EventTime = new Date().toISOString()

        this.EntityType = 'Page'
        this.Event = 'PageView' 
        this.EventType = 'PageEvent'
    }

}

export default Payload